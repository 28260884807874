import {
    Action,
    emptyActionCreator,
    PayloadAction,
    payloadActionCreator,
} from 'aos-helpers/src/helpers/ActionCreator'

import { JwtTokenWrapper } from '../../services/auth/types/JwtToken'
import { AosAirport } from '../../services/flightInformation/types/AosAirport'
import { AosUserLocalisation } from '../../services/users/types/AosUserLocalisation'

export const SIGN_IN_REQUEST = 'AUTH/SIGN_IN_REQUEST'
export type SignInRequestAction = Action<typeof SIGN_IN_REQUEST>
export const signInRequestAction = emptyActionCreator<SignInRequestAction>(SIGN_IN_REQUEST)

export const SIGN_IN_SUCCESS = 'AUTH/SIGN_IN_SUCCESS'
export type SignInSuccessAction = PayloadAction<typeof SIGN_IN_SUCCESS, JwtTokenWrapper>
export const signInSuccessAction = payloadActionCreator<SignInRequestAction, JwtTokenWrapper>(
    SIGN_IN_SUCCESS,
)

export const LOG_OUT = 'AUTH/LOG_OUT'
export type LogOutAction = Action<typeof LOG_OUT>
export const logOutAction = emptyActionCreator<LogOutAction>(LOG_OUT)

export const LOG_OUT_SUCCESS = 'AUTH/LOG_OUT_SUCCESS'
export type LogOutSuccessAction = Action<typeof LOG_OUT_SUCCESS>
export const logOutSuccessAction = emptyActionCreator<LogOutSuccessAction>(LOG_OUT_SUCCESS)

export const UNAUTHORIZED = 'AUTH/UNAUTHORIZED'
export type UnauthorizedAction = Action<typeof UNAUTHORIZED>
export const unauthorizedAction = emptyActionCreator<UnauthorizedAction>(UNAUTHORIZED)

export const RESTORE_AUTH = 'AUTH/RESTORE_AUTH'
export type RestoreAuthAction = PayloadAction<typeof RESTORE_AUTH, JwtTokenWrapper | undefined>
export const restoreAuthAction = payloadActionCreator<
    RestoreAuthAction,
    JwtTokenWrapper | undefined
>(RESTORE_AUTH)

// global actions
export const PREFERENCES_LOCATION_CHANGED = 'AUTH/PREFERENCES_LOCATION_CHANGED'
export type PreferencesLocationChangedAction = PayloadAction<
    typeof PREFERENCES_LOCATION_CHANGED,
    AosAirport
>
export const preferencesLocationChangedAction = payloadActionCreator<
    PreferencesLocationChangedAction,
    AosAirport
>(PREFERENCES_LOCATION_CHANGED)

export const PREFERENCES_LANGUAGE_CHANGED = 'AUTH/PREFERENCES_LANGUAGE_CHANGED'
export type PreferencesLanguageChangedAction = PayloadAction<
    typeof PREFERENCES_LANGUAGE_CHANGED,
    AosUserLocalisation
>
export const preferencesLanguageChangedAction = payloadActionCreator<
    PreferencesLanguageChangedAction,
    AosUserLocalisation
>(PREFERENCES_LANGUAGE_CHANGED)

export const PREFERENCES_CHANGED_START = 'AUTH/PREFERENCES_CHANGED_START'
export type PreferencesChangedStartAction = Action<typeof PREFERENCES_CHANGED_START>
export const preferencesChangedStartAction =
    emptyActionCreator<PreferencesChangedStartAction>(PREFERENCES_CHANGED_START)

export type CommonAuthAction =
    | SignInRequestAction
    | SignInSuccessAction
    | LogOutAction
    | LogOutSuccessAction
    | UnauthorizedAction
    | RestoreAuthAction
    | PreferencesChangedStartAction
