import { restoreAuthAction, signInSuccessAction } from 'aos-services/src/core/auth/actions'
import { KeycloakProvider } from 'aos-services/src/providers/keycloak'
import { registerAuthInterceptor } from 'aos-services/src/services/auth/authInterceptor'
import { registerUnauthorizedHandler } from 'aos-services/src/services/auth/unauthorizedHandler'
import { userManagementService } from 'aos-services/src/services/users/userManagementService'
import { appConfig } from 'appConfig'
import Keycloak from 'keycloak-js'
import React, { FC, PropsWithChildren, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { LoginStatus } from '../core/login/state'
import { State } from '../core/state'
import keycloak from './keycloak'

export const WebKeycloakProvider: FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useDispatch()
    const ready = useSelector((state: State) => state.login.loginStatus === LoginStatus.Success)

    const onLoginSuccess = useCallback(async (instance: Keycloak) => {
        registerAuthInterceptor(appConfig.apiUrl, instance.token || null)
        registerUnauthorizedHandler(() => {
            keycloak.updateToken()
        })

        await userManagementService.initUser()
        if (!instance.tokenParsed?.aosId) {
            await keycloak.updateToken(-1)
        } else {
            dispatch(
                signInSuccessAction({
                    accessToken: instance.token!,
                    payload: instance.tokenParsed!,
                }),
            )
            dispatch(restoreAuthAction(undefined))
        }
    }, [])

    const Provider = useMemo(() => KeycloakProvider(keycloak), [])

    return <Provider onLoginSuccess={onLoginSuccess}>{ready && children}</Provider>
}
