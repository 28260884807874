import { BlockSize } from 'aos-helpers/src/helpers/Block'
import { Color } from 'aos-ui-common/src/styles/Color'
import styled, { CSSObject } from 'styled-components'

import { MarginBoxProps, marginStyleGenerator } from '../base/Box'
import { SvgProps } from '../base/SvgProps'
import { SvgIcon } from './SvgIcon'
import { SvgImage } from './SvgImage'

export interface IconProps extends SvgProps, MarginBoxProps {
    iconSize?: BlockSize
    iconVariant?: IconVariant
    block?: boolean
    raw?: boolean
    transparent?: boolean
    color?: Color
    bgColor?: Color
}

export enum IconVariant {
    White = 1,
    Black,
    BlackGrey,
    Grey,
    Red,
    Blue,
    GreyTxt,
    Grey2,
    Yellow,
    Green,
    UiBlack4,
    Smart,
}

const variantColors: Record<IconVariant, Color> = {
    [IconVariant.White]: Color.White,
    [IconVariant.Black]: Color.Black,
    [IconVariant.BlackGrey]: Color.SideMenuInactive,
    [IconVariant.Grey]: Color.Grey3,
    [IconVariant.Red]: Color.Red,
    [IconVariant.Blue]: Color.Primary,
    [IconVariant.GreyTxt]: Color.WidgetSecondary,
    [IconVariant.Yellow]: Color.Yellow,
    [IconVariant.Green]: Color.LightGreen,
    [IconVariant.Grey2]: Color.Grey5,
    [IconVariant.UiBlack4]: Color.UiBlack4,
    [IconVariant.Smart]: Color.SmartColor,
}

export const Icon = styled(SvgImage)<IconProps>(
    ({ iconSize, iconVariant, block, transparent, raw = false, color, ...props }) => {
        const styles: CSSObject = {
            display: 'inline-block',
            fill: 'currentColor',
        }

        const margins = marginStyleGenerator(props)

        if (iconSize) {
            styles.width = `${iconSize}px`
            styles.height = `${iconSize}px`
            styles.flexShrink = 0
        }

        if (block) {
            styles.display = 'block'
        }

        if (iconVariant) {
            styles.fill = variantColors[iconVariant]
        }

        if (transparent) {
            styles.fill = 'transparent'
        }

        if (color) {
            styles.fill = color
        }

        if (!raw) {
            styles['path,rect,g:not(.raw),circle'] = {
                fill: 'inherit',
            }
        }

        return { ...styles, ...margins }
    },
)

export const svgIconForName = (name: SvgIconType) => SvgIcon[name] ?? SvgIcon.Default

export type SvgIconType = keyof typeof SvgIcon
